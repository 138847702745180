<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">切换酒店</div>
      </div>
    </div>
    <div class="hl-content">
      <el-radio-group v-model="hotelToBe">
        <el-radio-button
          v-for="item in choseHotelList"
          :key="item.id"
          :value="item.id"
          :label="item.id"
          >{{ item.hotelName }}</el-radio-button
        >
      </el-radio-group>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="quedingChange">确 定</el-button> -->
        <el-button class="addIdType" @click="quedingChange"> 确 定 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { postRequest, getRequest } from "@/api/api.js";
export default {
  data() {
    return {
      user: {},
      choseHotelList: [],
      hotelToBe: null,
      selectHotel: {},
    };
  },
  components: {},
  created() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.changeHotel();
  },
  methods: {
    changeHotel() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("getHotels", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          console.log(res.data);
          this.choseHotelList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    quedingChange() {
      this.choseHotelList.forEach((item) => {
        if (item.id == this.hotelToBe) {
          this.selectHotel.hotelId = item.id;
          this.selectHotel.hotelName = item.hotelName;
        }
      });
      this.selectHotel.userCode = this.user.userCode;
      this.selectHotel.userName = this.user.userName;
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
          this.HotelName = res.data.hotelName;
          localStorage.setItem("hotelInfor", JSON.stringify(res.data));
          localStorage.setItem("findPage", 0);
          this.getRoom();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    getRoom() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          this.$store.state.roomList = [];
          this.$store.state.roomList = res.data;
          this.$router.push("/roomControl");
          this.choseHotelVisable = false;
          //
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 60px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
  overflow: scroll;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.dialog-footer {
  margin-top: 6%;
}
.addIdType {
  width: 100px;
  height: 35px;
  background: #387dff;
  color: #fff;
  border-radius: 4px;
}
</style>
